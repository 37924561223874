module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-Q27WRR1LJK","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Segurat - Summa Insurance","short_name":"Segurat","lang":"es","start_url":"/","background_color":"#e5e7eb","theme_color":"#e5e7eb","display":"standalone","icon":"src/images/favicon-summa-seguros.gif","icons":[{"src":"src/images/segurat-summa-insurance-192-w.png","sizes":"192x192","type":"image/png"},{"src":"src/images/segurat-summa-insurance-512-w.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"36d4d7cc4b34585809cddf9eb1620a47"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
